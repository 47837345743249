<template>
  <div class="bg-white border border-gray-200 border-solid">
    <div class="chose__customer" v-if="status === status_types.STATUS_DRAFT">
      <v-autocomplete
        v-model="model"
        :items="items"
        :loading="isLoading"
          :search-input.sync="search"
          chips
          clearable
          hide-details
          hide-selected
          item-text="CompanyName"
          item-value="id"
          label="Kunde auswählen..."
          solo
          attach=""
          return-object
      >
        <template v-slot:no-data>
          <v-list-item>
            <v-list-item-title>
              Bitte geben Sie den Namen des <strong>Unternehmens</strong> ein
            </v-list-item-title>
          </v-list-item>
        </template>
        <template v-slot:selection="{ attr, on, item, selected }">
          <v-chip
              v-bind="attr"
              :input-value="selected"
              color="primary"
              class="white--text"
              v-on="on"
          >
            <v-icon left>
              mdi-account
            </v-icon>
            <span v-text="item.CompanyName"></span>
          </v-chip>
        </template>
        <template v-slot:item="{ item }">
          <v-list-item-avatar
              color="indigo"
              class="text-h5 font-weight-light white--text"
          >
            {{ item.CompanyName.charAt(0) + item.CompanyName.charAt(1) }}
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-text="item.CompanyName"></v-list-item-title>
            <v-list-item-subtitle v-text="item.DebitorNo"></v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-icon>mdi-account</v-icon>
          </v-list-item-action>
        </template>
      </v-autocomplete>
    </div>
    <div
        v-if="itemSelect"
        class="flex flex-col p-4 bg-white border border-gray-200 border-solid"
        style="min-height: 170px"
    >
      <div class="pa-6 ma-2">
        <span>{{ itemSelect.CompanyName }}<br/></span>
        <span v-if="itemSelect.CompanyName2">{{ itemSelect.CompanyName2 }}<br/></span>
        <span>{{ itemSelect.Street ? itemSelect.Street : "n/a" }}<br/></span>
        <span v-if="itemSelect.Street2">{{ itemSelect.Street2 }}<br/></span>
        <span>{{
            (itemSelect.PostalCode ? itemSelect.PostalCode : "n/a") +
              (itemSelect.City ? " " + itemSelect.City : "n/a")
          }}
        </span><br/>
        <span v-if="itemSelect.CountryCode">{{ itemSelect.CountryCode }}<br/></span>
      </div>
    </div>
    <div v-else>
      <div slot="activator" style="min-height: 170px"></div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import StatusTypes from "../../../../stub/statustypes";

export default {
  components: {},
  name: "CustomerSelect",
  props: {
    selectedCustomer: {
      type: Object,
      default: null
    },
    status: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      itemSelect: null,
      isLoading: false,
      items: [],
      model: null,
      search: null,
      tab: null,
      status_types: {...StatusTypes}
    };
  },
  created() {
    this.itemSelect = this.selectedCustomer;
  },
  methods: {
    updateCustomer() {
      this.$emit("update", this.itemSelect);
    }
  },
  watch: {
    selectedCustomer() {
      this.itemSelect = this.selectedCustomer;
    },
    model(val) {
      if (val != null) this.tab = 0;
      else this.tab = null;

      this.itemSelect = val;
      this.updateCustomer();
    },
    // eslint-disable-next-line no-unused-vars
    search(val) {
      // Items have already been loaded
      if (this.items.length > 0) return;

      this.isLoading = true;

      // Lazily load input items
      //fetch("https://api.coingecko.com/api/v3/coins/list")
      ApiService.query("/clients/all")
          //.then(res => res.clone().json())
          .then(res => {
            this.items = res.data.clients;
          })
          // eslint-disable-next-line no-unused-vars
          .catch(err => {
          })
          .finally(() => (this.isLoading = false));
    }
  }
};
</script>

<style scoped>
.chose__customer {
  font-size: 14px;
  text-align: center;
}
</style>
