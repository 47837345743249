<template>
  <tr class="box-border bg-white border border-gray-200 border-solid rounded-b">
    <td colspan="5" class="p-0 text-left align-top">
      <table style="width: 100%">
        <colgroup>
          <col style="width: 35%" />
          <col style="width: 12.5%" />
          <col style="width: 10%" />
          <col style="width: 12.5%" />
          <col style="width: 15%" />
        </colgroup>
        <tbody>
          <tr>
            <td class="px-5 py-4 text-left align-top">
              <v-combobox
                :disabled="status !== status_types.STATUS_DRAFT"
                v-model="item.name"
                :items="itemsDB"
                :loading="isLoading"
                :search-input.sync="search"
                placeholder="Artikel*"
                dense
                background-color="#f7f7f7"
                type="text"
                filled
                clearable
                item-text="name"
                @change="onChange"
                attach=""
              >
                <template v-slot:item="{ item }">
                  <v-list-item-avatar
                    color="indigo"
                    class="text-h5 font-weight-light white--text"
                  >
                    {{ item.name.charAt(0) + item.name.charAt(1) }}
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.name"></v-list-item-title>
                    <v-list-item-subtitle
                      v-text="item.description"
                    ></v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-list-item-subtitle
                      v-text="formatMoney(item.price, '')"
                    ></v-list-item-subtitle>
                  </v-list-item-action>
                </template>
              </v-combobox>
              <v-textarea
                v-model="item.description"
                type="text"
                row-height="10px"
                dense
                auto-grow
                filled
                hide-details
                placeholder="Beschreibung (optional)"
                background-color="#f7f7f7"
                :disabled="status !== 'DRAFT'"
                @keyup="updateItem"
              />
            </td>
            <td class="px-5 py-4 text-right align-top">
              <v-text-field
                v-model="item.quantity"
                :invalid="item.quantity.$error"
                type="number"
                filled
                dense
                background-color="#f7f7f7"
                :disabled="status !== status_types.STATUS_DRAFT"
                @keyup="updateItem"
              />
              <div v-if="item.quantity.$error">
                <span v-if="!item.quantity.maxLength" class="text-danger">
                  {{ $t("validation.quantity_maxlength") }}
                </span>
              </div>
            </td>
            <td class="py-4 text-left align-top">
              <v-text-field
                :value="unit_item[0] ? unit_item[0].name : ''"
                filled
                auto-grow
                background-color="#f7f7f7"
                name="input-7-1"
                dense
                clearable
                disabled
                attach=""
              ></v-text-field>
            </td>
            <td class="px-5 py-4 text-left align-top">
              <div class="flex flex-col">
                <div class="flex-auto flex-fill bd-highlight">
                  <div class="relative w-full">
                    <v-currency-field
                      v-model="price"
                      :invalid="item.price.$error"
                      type="number"
                      filled
                      dense
                      background-color="#f7f7f7"
                      :disabled="status !== status_types.STATUS_DRAFT"
                      @keyup="updateItem"
                    />
                  </div>
                  <div v-if="item.price.$error">
                    <span v-if="!item.price.maxLength" class="text-danger">
                      {{ $t("validation.price_maxlength") }}
                    </span>
                  </div>
                </div>
              </div>
            </td>
            <td class="px-5 py-2 text-right align-top">
              <div class="flex items-center justify-end text-sm">
                <span>
                  <!--                  <div v-html="formatMoney(item.price * item.quantity, currency)" />-->
                  <v-text-field
                    :label="formatMoney(item.price * item.quantity, currency)"
                    disabled
                  />
                </span>
                <span>
                  <v-icon
                    color="primary"
                    @click="removeItem"
                    v-if="status === status_types.STATUS_DRAFT"
                  >
                    mdi-delete
                  </v-icon>
                </span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </td>
  </tr>
</template>

<script>
import InvoiceStub from "../../../../stub/invoice";
import StatusTypes from "../../../../stub/statustypes";
import ApiService from "@/core/services/api.service";
import { formatMoney } from "@/helpers/utilities";

const {
  required,
  minValue,
  between,
  maxLength
} = require("vuelidate/lib/validators");

export default {
  components: {},
  name: "InvoiceItem",
  props: {
    itemData: {
      type: Object,
      default: null
    },
    index: {
      type: Number,
      default: null
    },
    type: {
      type: String,
      default: ""
    },
    status: {
      type: String,
      default: ""
    },
    customerSelect: {
      type: Number,
      default: null
    },
    currency: {
      type: [Object, String],
      required: true
    },
    invoiceItems: {
      type: Array,
      default: null
    },
    unit_item: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      isClosePopup: false,
      itemSelect: null,
      item: { ...this.itemData },
      money: {
        decimal: ".",
        thousands: ",",
        prefix: "$ ",
        precision: 2,
        masked: false
      },
      status_types: { ...StatusTypes },
      isSelected: false,
      isLoading: false,
      search: null,
      itemsDB: [],
      itemDBModel: null
    };
  },
  created() {
    this.item.unit_id = 1;
  },
  computed: {
    customerCurrency() {
      if (this.currency) {
        return {
          decimal: this.currency.decimal_separator,
          thousands: this.currency.thousand_separator,
          prefix: this.currency.symbol + " ",
          precision: this.currency.precision,
          masked: false
        };
      } else {
        return this.defaultCurrenctForInput;
      }
    },
    showRemoveItemIcon() {
      return this.invoiceItems.length !== 1;
    },
    price: {
      get: function() {
        if (parseFloat(this.item.price) > 0) {
          return this.item.price / 1000;
        }
        return this.item.price;
      },
      set: function(newValue) {
        if (parseFloat(newValue) < 0) {
          newValue *= -1;
        } else {
          this.item.price = newValue * 1000;
        }
      }
    }
  },
  watch: {
    item: {
      handler: "updateItem",
      deep: true
    },
    modalActive(val) {
      if (!val) {
        this.isSelected = false;
      }
    },
    search(val) {
      this.item.name = val ? val : "";
      if (val && (val.length < 2 || val.length > 10)) return;
      this.isLoading = true;

      if (this.customerSelect) {
        ApiService.query("/items/search/" + this.customerSelect, val)
          .then(res => {
            this.itemsDB = res.data.items;
          })
          // eslint-disable-next-line no-unused-vars
          .catch(err => {})
          .finally(() => (this.isLoading = false));
      } else {
        ApiService.query("/items/search", val)
          .then(res => {
            this.itemsDB = res.data.items;
          })
          // eslint-disable-next-line no-unused-vars
          .catch(err => {})
          .finally(() => (this.isLoading = false));
      }
    }
  },
  validations() {
    return {
      item: {
        name: {
          required
        },
        quantity: {
          required,
          minValue: minValue(0),
          maxLength: maxLength(20)
        },
        price: {
          required,
          minValue: minValue(1),
          maxLength: maxLength(20)
        },
        discount_val: {
          between: between(0, this.maxDiscount)
        },
        description: {
          maxLength: maxLength(65000)
        }
      }
    };
  },
  methods: {
    onChange(val) {
      if (val) {
        this.item.name = val.name;
        this.item.description = val.description;
        this.item.price = val.price;
        this.item.unit_id = val.unit_id;
      }
    },
    formatMoney,
    updateTax(data) {
      this.$set(this.item.taxes, data.index, data.item);

      let lastTax = this.item.taxes[this.item.taxes.length - 1];

      if (lastTax.tax_type_id !== 0) {
        //this.item.taxes.push({ ...TaxStub, id: Guid.raw() });
      }

      this.updateItem();
    },
    removeTax(index) {
      this.item.taxes.splice(index, 1);

      this.updateItem();
    },
    taxWithPercentage({ name, percent }) {
      return `${name} (${percent}%)`;
    },
    searchVal(val) {
      this.item.name = val;
    },
    deselectItem() {
      this.item = {
        ...InvoiceStub,
        id: this.item.id
        //taxes: [{ ...TaxStub, id: Guid.raw() }]
      };
      this.$nextTick(() => {
        this.$refs.itemSelect.$refs.baseSelect.$refs.search.focus();
      });
    },
    onSelectItem(item) {
      this.item.name = item.name;
      this.item.price = item.price;
      this.item.item_id = item.id;
      this.item.description = item.description;
      this.item.unit_name = item.unit_name;
    },
    updateItem() {
      if (this.item.price > 0) {
        this.$emit("update", {
          index: this.index,
          item: {
            ...this.item,
            total: this.item.price * this.item.quantity
            //totalSimpleTax: this.totalSimpleTax,
            //totalCompoundTax: this.totalCompoundTax,
            //totalTax: this.totalTax,
            //tax: this.totalTax,
            //taxes: [...this.item.taxes]
          }
        });
      }
    },
    removeItem() {
      this.$emit("remove", this.index);
    },
    validateItem() {
      this.$v.item.$touch();

      if (this.item !== null) {
        this.$emit("itemValidate", this.index, !this.$v.$invalid);
      } else {
        this.$emit("itemValidate", this.index, false);
      }
    }
  }
};
</script>
