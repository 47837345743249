var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-white border border-gray-200 border-solid"},[(_vm.status === _vm.status_types.STATUS_DRAFT)?_c('div',{staticClass:"chose__customer"},[_c('v-autocomplete',{attrs:{"items":_vm.items,"loading":_vm.isLoading,"search-input":_vm.search,"chips":"","clearable":"","hide-details":"","hide-selected":"","item-text":"CompanyName","item-value":"id","label":"Kunde auswählen...","solo":"","attach":"","return-object":""},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v(" Bitte geben Sie den Namen des "),_c('strong',[_vm._v("Unternehmens")]),_vm._v(" ein ")])],1)]},proxy:true},{key:"selection",fn:function(ref){
var attr = ref.attr;
var on = ref.on;
var item = ref.item;
var selected = ref.selected;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"white--text",attrs:{"input-value":selected,"color":"primary"}},'v-chip',attr,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-account ")]),_c('span',{domProps:{"textContent":_vm._s(item.CompanyName)}})],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-avatar',{staticClass:"text-h5 font-weight-light white--text",attrs:{"color":"indigo"}},[_vm._v(" "+_vm._s(item.CompanyName.charAt(0) + item.CompanyName.charAt(1))+" ")]),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.CompanyName)}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(item.DebitorNo)}})],1),_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-account")])],1)]}}],null,false,3526893536),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1):_vm._e(),(_vm.itemSelect)?_c('div',{staticClass:"flex flex-col p-4 bg-white border border-gray-200 border-solid",staticStyle:{"min-height":"170px"}},[_c('div',{staticClass:"pa-6 ma-2"},[_c('span',[_vm._v(_vm._s(_vm.itemSelect.CompanyName)),_c('br')]),(_vm.itemSelect.CompanyName2)?_c('span',[_vm._v(_vm._s(_vm.itemSelect.CompanyName2)),_c('br')]):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.itemSelect.Street ? _vm.itemSelect.Street : "n/a")),_c('br')]),(_vm.itemSelect.Street2)?_c('span',[_vm._v(_vm._s(_vm.itemSelect.Street2)),_c('br')]):_vm._e(),_c('span',[_vm._v(_vm._s((_vm.itemSelect.PostalCode ? _vm.itemSelect.PostalCode : "n/a") + (_vm.itemSelect.City ? " " + _vm.itemSelect.City : "n/a"))+" ")]),_c('br'),(_vm.itemSelect.CountryCode)?_c('span',[_vm._v(_vm._s(_vm.itemSelect.CountryCode)),_c('br')]):_vm._e()])]):_c('div',[_c('div',{staticStyle:{"min-height":"170px"},attrs:{"slot":"activator"},slot:"activator"})])])}
var staticRenderFns = []

export { render, staticRenderFns }